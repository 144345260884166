import { ValueOf } from "../../pojo/Util";
import { Item } from "../../pojo/Item";
import { RuleActionTarget } from "../../services/rule.tree.service";
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";

export namespace Rules {
    import DowChar = EventSummary.DowChar;
    export type Conjunction = "and" | "or";
    export type Operator = ValueOf<MappedOperators<Omit<typeof operators, "none">>>;
    export type ValueType = ValueOf<typeof valueType>;
    export type TypeId = Type["id"];
    export type Type = ValueOf<typeof type>;
    export type SourceId = Source["id"];
    export type Source = ValueOf<typeof source>;
    export type AttributeType = ValueOf<typeof attributeType>;
    export type AdditionalTimeId = ValueOf<typeof additionalTime>["itemId"];
    export type Target = ValueOf<typeof target>;
    export type TargetId = Target["id"];
    export type Action = Target["valueType"]["action"] | "populateContactRole";
    export type Category = "form" | "match" | "matchForm" | "formValidation";
    export type ConditionFilterMap = Partial<Record<SearchCriteriaType["type"], string>>;

    export type Rule = {
        conditions: Conditions;
        name: string;
        category: Category;
        subCategory?: string;
        id: number;
        active: boolean;
        targets: Partial<Record<Action, Rules.ActionTarget[]>>;
        conditionFilterMap?: ConditionFilterMap;
    };

    export type Conditions = {
        operator: Conjunction;
        children: (Conditions | Condition)[];
    };

    export type Condition = {
        type: TypeId;
        sourceItem?: SourceItem;
        operator: Operator;
        values: ConditionValue[];
    };

    export type SourceItem = {
        itemId: number;
        attributeType?: AttributeType;
        itemName?: string;
        itemTypeId?: number;
        children?: ItemValue[];
    };

    export type ConditionValue =
        | boolean
        | string
        | number
        | Date
        | ItemValue
        | Date[]
        | number[]
        | Record<DowChar, boolean>
        | { days: number; type: string };

    export type ItemValue = {
        itemId: number | string;
        itemName: string;
    };

    export type ActionTarget = RuleActionTarget & {
        number?: number; // Currently only for resources
        contact?: ActionTarget; // Only for contact roles
        checkbox?: boolean; // Only for multiselect targets with checkbox
    };

    export type AdditionalTime = { days: number; hours: number; minutes: number };
    export type AdditionalTimes = {
        setup: AdditionalTime;
        pre: AdditionalTime;
        post: AdditionalTime;
        takedown: AdditionalTime;
    };

    // Operators are limited to 25 characters in DB
    export const operators = {
        none: [] as const,
        intersection: ["in", "not in"],
        intersectionOptional: ["in", "not in", "any", "none"],
        comparative: ["=", "<", "<=", ">=", ">"],
        equality: ["="],
        string: ["=", "in", "not in", "contains"],
        date: ["between", "=", "<", "<=", ">=", ">"],
        relativeDate: ["After", "Before"],
        is: ["is"],
        in: ["in"],
    } as const;
    type MappedOperators<T extends Record<string, readonly string[]>> = { [P in keyof T]: T[P][number] };

    export const valueType = {
        CustomAttribute: { type: "attribute", operators: operators.none },
        Location: {
            type: "multiselect",
            criterion: "locations",
            filter: "",
            label: "Locations",
            operators: operators.intersectionOptional,
        },
        LocationSearch: {
            type: "search",
            itemType: Item.Ids.Location,
            public: true,
            operators: operators.intersection,
        },
        LocationLayout: {
            type: "multiselect",
            criterion: "locationLayouts",
            filter: "",
            label: "Layouts",
            operators: operators.intersection,
        },
        Resource: {
            type: "multiselect",
            criterion: "resources",
            filter: "",
            label: "Resources",
            operators: operators.intersectionOptional,
        },
        ResourceSearch: {
            type: "search",
            itemType: Item.Ids.Resource,
            public: true,
            operators: operators.intersection,
        },
        Organization: {
            type: "multiselect",
            criterion: "organizations",
            filter: "",
            label: "Organizations",
            operators: operators.intersectionOptional,
        },
        OrganizationSearch: {
            type: "search",
            itemType: Item.Ids.Organization,
            public: true,
            operators: operators.intersection,
        },
        SecurityGroup: {
            type: "multiselect",
            criterion: "securityGroups",
            filter: "",
            label: "Security Groups",
            operators: operators.intersection,
        },
        EventType: {
            type: "multiselect",
            criterion: "eventTypes",
            filter: "",
            label: "Event Types",
            operators: operators.intersectionOptional,
        },
        EventCategory: {
            type: "multiselect",
            criterion: "eventCategories",
            filter: "",
            label: "Categories",
            operators: operators.intersectionOptional,
        },
        Requirement: {
            type: "multiselect",
            criterion: "eventRequirements",
            filter: "requirement_type=6",
            label: "Requirements",
            operators: operators.intersectionOptional,
        },
        CalendarRequirement: {
            type: "multiselect",
            criterion: "eventRequirements",
            filter: "requirement_type=7",
            label: "Calendar Requirements",
            operators: operators.intersectionOptional,
        },
        Integer: { type: "number", flavor: "integer", operators: operators.comparative },
        Float: { type: "number", flavor: "float", operators: operators.comparative },
        Boolean: { type: "boolean", operators: operators.equality },
        Text80: { type: "text", maxLength: 80, operators: operators.string },
        Text250: { type: "text", maxLength: 250, operators: operators.string },
        Text10000: { type: "textarea", maxLength: 10_000, operators: operators.string },
        Discrete: { type: "discrete", operators: operators.string },
        Date: { type: "date", operators: operators.comparative },
        RelativeDate: { type: "relativeDate", operators: operators.relativeDate },
        Time: { type: "time", operators: operators.comparative },
        DateTime: { type: "datetime", operators: operators.comparative },
        OccurrenceDate: { type: "occurrenceDate", operators: operators.date },
        OccurrenceTime: { type: "occurrenceTime", operators: operators.date },
        OccurrenceDow: { type: "occurrenceDow", operators: operators.equality },
        State: {
            type: "multiselect",
            criterion: "eventStates",
            filter: "",
            label: "States",
            operators: operators.intersection,
        },
        Checkbox: { type: "checkbox", operators: operators.equality },
        MatchQuestion: { type: "matchQuestion", criterion: "matchQuestions", filter: "", operators: operators.in },
        FormConfig: {
            type: "multiselect",
            criterion: "formConfigs",
            filter: "",
            label: "Configs",
            operators: operators.intersection,
        },
        FormMode: {
            type: "dropdown",
            options: [
                { itemId: "create", itemName: "Creating" },
                { itemId: "edit", itemName: "Editing" },
            ],
            operators: operators.is,
        },
    } as const;

    export const valuelessOperators = new Set(["any", "none"]);

    export const attributeType = {
        DateTime: "E",
        Date: "D",
        Time: "T",
        Boolean: "B",
        Organization: 2,
        Location: 4,
        Resource: 6,
        Float: "F",
        Integer: "N",
        Text: "S",
        LongText: "X",
        URL: "R",
    } as const;

    export const attributeValueType = {
        E: valueType.DateTime,
        D: valueType.Date,
        T: valueType.Time,
        B: valueType.Boolean,
        2: valueType.Organization,
        4: valueType.Location,
        6: valueType.Resource,
        F: valueType.Float,
        N: valueType.Integer,
        S: valueType.Text80,
        X: valueType.Text10000,
        R: valueType.Text250,
    } as const;

    export const additionalTime = {
        None: { itemId: 0, itemName: "None" },
        PrePost: { itemId: 1, itemName: "Pre & Post" },
        SetupTakedown: { itemId: 2, itemName: "Setup & Takedown" },
    } as const;

    export const ifChanged = {
        Yes: { itemId: 0, itemName: "Yes" },
        No: { itemId: 1, itemName: "No" },
    };

    export const sourceItems = {
        additionalTime: {
            label: "Include",
            options: [additionalTime.None, additionalTime.PrePost, additionalTime.SetupTakedown],
        },
        ifChanged: {
            label: "Only when changed",
            options: [ifChanged.No, ifChanged.Yes],
        },
    } as const;

    export const type = {
        CustomAttribute: {
            id: 11,
            label: "Custom Attribute",
            valueType: valueType.CustomAttribute,
            hasSourceItem: true,
        },
        Location: { id: 4, label: "Items", valueType: valueType.Location },
        // Location layouts have ID 430 in search criteria
        LocationLayout: { id: 430, label: "Layout", valueType: valueType.LocationLayout },
        Resource: { id: 6, label: "Items", valueType: valueType.Resource },
        Organization: { id: 2, label: "Items", valueType: valueType.Organization },
        // Searches have ID X05 in search criteria
        LocationSearch: { id: 405, label: "Search", valueType: valueType.LocationSearch },
        ResourceSearch: { id: 605, label: "Search", valueType: valueType.ResourceSearch },
        OrganizationSearch: { id: 205, label: "Search", valueType: valueType.OrganizationSearch },
        SecurityGroup: { id: 99, label: "Security Group", valueType: valueType.SecurityGroup },
        EventType: { id: 19, label: "Event Type", valueType: valueType.EventType },
        EventCategory: { id: 120, label: "Event Category", valueType: valueType.EventCategory },
        // Requirements really have an ID of 9, but we need them to be separate here.
        // Normal requirements have req type 6, and calendar requirements have typ 7, hence 96 and 97
        // Actually, elsewhere requirements have ID 7, but 9 is used in rules for whatever reason.
        Requirement: { id: 96, label: "Requirement", valueType: valueType.Requirement },
        CalendarRequirement: { id: 97, label: "Calendar Requirement", valueType: valueType.CalendarRequirement },
        // In search criteria Expected Head Count and Registered Head Count are both id 141, so let's use 1410 and 1411 here
        ExpectedHeadcount: { id: 1410, label: "Expected Head Count", valueType: valueType.Integer },
        RegisteredHeadcount: { id: 1411, label: "Registered Head Count", valueType: valueType.Integer },
        // In search criteria event details are all id 100, so let's append to that
        Date: {
            id: 1000,
            label: "Date",
            valueType: valueType.OccurrenceDate,
            hasSourceItem: true,
            sourceItems: sourceItems.additionalTime,
        },
        RelativeDate: {
            id: 1003,
            label: "Relative Date",
            valueType: valueType.RelativeDate,
            hasSourceItem: true,
            sourceItems: sourceItems.additionalTime,
        },
        TimeOfDay: {
            id: 1001,
            label: "Time of Day",
            valueType: valueType.OccurrenceTime,
            hasSourceItem: true,
            sourceItems: sourceItems.additionalTime,
        },
        DayOfWeek: {
            id: 1002,
            label: "Day of Week",
            valueType: valueType.OccurrenceDow,
            hasSourceItem: true,
            sourceItems: sourceItems.additionalTime,
        },
        // In search criteria event state is 101
        EventState: {
            id: 101,
            label: "State",
            valueType: valueType.State,
            hasSourceItem: true,
            sourceItems: sourceItems.ifChanged,
        },
        Affirmation: {
            id: -1,
            label: "Affirmation",
            valueType: valueType.Checkbox,
        },
        MatchQuestion: {
            id: 7001,
            label: "Questions and Answers",
            valueType: valueType.MatchQuestion,
            hasSourceItem: true,
        },
        FormConfig: {
            id: -2,
            label: "Form Config",
            valueType: valueType.FormConfig,
        },
        FormMode: {
            id: -3,
            label: "Form Mode",
            valueType: valueType.FormMode,
        },
    } as const;

    export const source = {
        CustomAttribute: { id: type.CustomAttribute.id, label: type.CustomAttribute.label },
        Location: { id: Item.Ids.Location, label: "Location", isGroup: true },
        Resource: { id: Item.Ids.Resource, label: "Resource", isGroup: true },
        Organization: { id: Item.Ids.Organization, label: "Organization", isGroup: true },
        SecurityGroup: { id: type.SecurityGroup.id, label: type.SecurityGroup.label },
        EventType: { id: type.EventType.id, label: type.EventType.label },
        EventCategory: { id: type.EventCategory.id, label: type.EventCategory.label },
        Requirement: { id: type.Requirement.id, label: type.Requirement.label },
        CalendarRequirement: { id: type.CalendarRequirement.id, label: type.CalendarRequirement.label },
        EventDetails: { id: Item.Ids.Event, label: "Event Details", isGroup: true },
        Affirmation: { id: type.Affirmation.id, label: type.Affirmation.label },
        MatchQuestion: { id: type.MatchQuestion.id, label: type.MatchQuestion.label },
        FormConfig: { id: type.FormConfig.id, label: type.FormConfig.label },
        FormMode: { id: type.FormMode.id, label: type.FormMode.label },
    } as const;

    export const sources: Record<Category, Source[]> = {
        form: [
            source.CustomAttribute,
            source.Location,
            source.Resource,
            source.Organization,
            source.SecurityGroup,
            source.EventType,
            source.EventCategory,
            source.Requirement,
            source.CalendarRequirement,
            source.Affirmation,
            source.FormConfig,
            source.FormMode,
            source.EventDetails,
        ],
        match: [source.MatchQuestion],
        matchForm: [source.MatchQuestion],
        formValidation: [
            source.CustomAttribute,
            source.Location,
            source.Resource,
            source.Organization,
            source.SecurityGroup,
            source.EventType,
            source.EventCategory,
            source.Requirement,
            source.CalendarRequirement,
            source.Affirmation,
            source.EventDetails,
            source.FormConfig,
            source.FormMode,
        ],
    };

    export const groups = {
        [Item.Ids.Event]: [
            type.Date,
            type.RelativeDate,
            type.DayOfWeek,
            type.TimeOfDay,
            type.ExpectedHeadcount,
            type.RegisteredHeadcount,
            type.EventState,
        ],
        [Item.Ids.Location]: [type.Location, type.LocationSearch, type.LocationLayout],
        [Item.Ids.Resource]: [type.Resource, type.ResourceSearch],
        [Item.Ids.Organization]: [type.Organization, type.OrganizationSearch],
    };

    export const typeIdToSource = {} as Record<TypeId, Source>;
    for (let entry of Object.entries(source)) {
        const source1 = entry[1];
        if ("isGroup" in source1 && source1.isGroup) {
            for (let type1 of groups[source1.id]) typeIdToSource[type1.id] = source1;
        } else {
            const key = source1.id as TypeId;
            typeIdToSource[key] = source1;
        }
    }

    export const typeIdToType = {} as Record<TypeId, Type>;
    for (let type1 of Object.values(type)) typeIdToType[type1.id] = type1;

    export const allowedCustomAttributeTypes: Set<AttributeType> = new Set([
        attributeType.Text,
        attributeType.LongText,
        attributeType.URL,
        attributeType.DateTime,
        attributeType.Date,
        attributeType.Time,
        attributeType.Integer,
        attributeType.Float,
        attributeType.Boolean,
        attributeType.Organization,
        attributeType.Location,
        attributeType.Resource,
    ]);

    export const actionType = {
        Attribute: {
            type: "multiselectWithCheckbox",
            action: "addCustAtrb",
            criterion: "eventCustomAttributes",
            filter: "",
            label: "Custom Attributes",
            checkboxLabel: "Required",
        },
        Resource: {
            type: "multiselectWithQuantity",
            action: "addResource",
            criterion: "resources",
            filter: "",
            label: "Resources",
        },
        RecommendedResource: {
            type: "multiselect",
            action: "addRecommendedResource",
            criterion: "resources",
            filter: "",
            label: "Recommended Resources",
        },
        RecommendedLocation: {
            type: "multiselect",
            action: "addRecommendedLocation",
            criterion: "locations",
            filter: "",
            label: "Recommended Locations",
        },
        Requirement: {
            type: "multiselect",
            action: "addRequirement",
            criterion: "eventRequirements",
            filter: "requirement_type=6",
            label: "Requirements",
        },
        CalendarRequirement: {
            type: "multiselect",
            action: "addCalRequirement",
            criterion: "eventRequirements",
            filter: "requirement_type=7",
            label: "Calendar Requirements",
        },
        Category: {
            type: "multiselect",
            action: "addCategory",
            criterion: "eventCategories",
            filter: "",
            label: "Calendar Requirements",
        },
        ContactRole: { type: "contactRole", action: "addContactRole" },
        PrimaryOrganization: {
            type: "dropdown",
            action: "setPrimaryOrganization",
            criterion: "organizations",
            filter: "",
            label: "Primary Organization",
        },
        AdditionalOrganization: {
            type: "multiselect",
            action: "addAdditionalOrganization",
            criterion: "organizations",
            filter: "",
            label: "Additional Organizations",
        },
        Alert: { type: "richText", action: "alertUser", label: "Alert" },
        Notify: { type: "textarea", action: "notifyUser", label: "Notification" },
        Confirm: { type: "richText", action: "confirm", label: "Confirm" },
        PreventSave: { type: "yesNo", action: "preventSave", label: "Prevent Save" },
        MatchGroup: { type: "textarea", action: "matchGroup", label: "Match Group" },
        SetState: {
            type: "dropdown",
            action: "setState",
            label: "Prevent Save",
            criterion: "eventStates",
            filter: "",
        },
        MinAdditionalTime: {
            type: "additionalTime",
            action: "minAdditionalTime",
            label: "Min Additional Time",
        },
        HideQuestion: {
            type: "multiselect",
            action: "hideQuestion",
            label: "Hide",
            criterion: "matchQuestionsAll",
            filter: "",
        },
        ShowQuestion: {
            type: "multiselect",
            action: "showQuestion",
            label: "Show",
            criterion: "matchQuestionsAll",
            filter: "",
        },
    } as const;

    export const target = {
        Attributes: { id: 11, label: "Add Custom Attributes", valueType: actionType.Attribute },
        Resources: { id: Item.Ids.Resource, label: "Add Resources", valueType: actionType.Resource },
        RecommendedResources: { id: 6, label: "Add Recommended Resources", valueType: actionType.RecommendedResource },
        RecommendedLocations: { id: 4, label: "Add Recommended Locations", valueType: actionType.RecommendedLocation },
        Requirements: { id: 9, label: "Add Requirements", valueType: actionType.Requirement },
        CalendarRequirements: { id: 9, label: "Add Calendar Requirements", valueType: actionType.CalendarRequirement },
        Categories: { id: 120, label: "Add Categories", valueType: actionType.Category },
        ContactRole: { id: 16, label: "Add Contact Role", valueType: actionType.ContactRole },
        PrimaryOrganization: {
            id: Item.Ids.Organization,
            label: "Set Primary Organization",
            valueType: actionType.PrimaryOrganization,
        },
        AdditionalOrganizations: {
            id: Item.Ids.Organization,
            label: "Add Additional Organizations",
            valueType: actionType.AdditionalOrganization,
        },
        Alert: { id: -1, label: "Alert User", valueType: actionType.Alert },
        Notify: { id: -2, label: "Notify User", valueType: actionType.Notify },
        MatchGroup: { id: -3, label: "Match Group", valueType: actionType.MatchGroup },
        Confirm: { id: -4, label: "Confirm", valueType: actionType.Confirm },
        PreventSave: { id: -5, label: "Prevent Save", valueType: actionType.PreventSave },
        SetState: { id: 101, label: "Set State", valueType: actionType.SetState },
        HideQuestion: { id: -6, label: "Hide", valueType: actionType.HideQuestion },
        ShowQuestion: { id: -7, label: "Show", valueType: actionType.ShowQuestion },
        MinAdditionalTime: { id: -8, label: "Min Additional Time", valueType: actionType.MinAdditionalTime },
    } as const;

    export const targetOptions: Record<Category, Target[]> = {
        form: [
            target.Attributes,
            target.Resources,
            target.RecommendedResources,
            target.RecommendedLocations,
            target.Requirements,
            target.CalendarRequirements,
            target.Categories,
            target.ContactRole,
            target.PrimaryOrganization,
            target.AdditionalOrganizations,
            target.MinAdditionalTime,
            target.Alert,
            target.Notify,
        ],
        match: [target.MatchGroup],
        matchForm: [target.HideQuestion, target.ShowQuestion],
        formValidation: [target.Alert, target.Notify, target.Confirm, target.PreventSave, target.SetState],
    };

    export const targetActionToTarget = {} as Record<Action, Target>;
    for (let t of Object.values(target)) targetActionToTarget[t.valueType.action] = t;

    export const itemTypes: Set<TypeId> = new Set(
        Object.values(type)
            .filter((type) => type.valueType.type === "multiselect")
            .map((type) => type.id),
    );

    export const itemAttributes: Set<AttributeType> = new Set(
        Object.values(attributeType).filter((type) => attributeValueType[type].type === "multiselect"),
    );

    export const asyncValueTypes: Set<ValueType["type"]> = new Set(["search"] as const);
    export const asyncTypes: Set<TypeId> = new Set(
        Object.values(type)
            .filter((type) => asyncValueTypes.has(type.valueType.type))
            .map((type) => type.id),
    );
}
