import { Rule, RuleAction, RuleItem, RuleItems, RuleSource, RuleValue } from "../../services/rule.tree.service";
import { S25Util } from "../../util/s25-util";
import { Rules } from "./s25.rule.const";
import { EventSummary } from "../s25-swarm-schedule/s25.event.summary.service";
import DowChar = EventSummary.DowChar;
import ItemValue = Rules.ItemValue;
import { EventStateConst } from "../../services/event.state.change.service";
import ConditionValue = Rules.ConditionValue;

export class S25RuleTreeUtil {
    public static isItemType(type: Rules.TypeId) {
        return Rules.itemTypes.has(type);
    }

    public static isItemAttribute(attribute: Rules.AttributeType) {
        return Rules.itemAttributes.has(attribute);
    }

    public static parseRules(rules: Rule[]): Rules.Rule[] {
        const parsedRules: Rules.Rule[] = [];
        for (const rule of rules) {
            try {
                const parsedRule = S25RuleTreeUtil.parseRule(rule);
                parsedRules.push(parsedRule);
            } catch (err: unknown) {
                console.error(`Could not parse rule: ${rule.rule_name}`);
            }
        }
        return parsedRules;
    }

    public static parseRule(rule: Rule): Rules.Rule {
        const { rule_name, rule_cat, sub_cat, root_rule_id, active } = rule;
        const conditions = S25RuleTreeUtil.parseRuleItems(rule.item);
        const targets = S25RuleTreeUtil.parseTargets(rule);

        return {
            conditions,
            name: rule_name,
            category: rule_cat as Rules.Category,
            subCategory: sub_cat,
            id: root_rule_id,
            active: !!active,
            targets,
        };
    }

    public static parseTargets(rule: Rule) {
        const actionMap: Partial<Record<RuleAction["action"], Rules.ActionTarget[]>> = Object.fromEntries(
            rule.item.actions?.map((action) => [action.action, action.targets]) ?? [],
        );

        // Consolidate contact and role
        for (let contact of actionMap.populateContactRole || []) {
            const roleId = Number(contact.itemValue.match(/-?\d+/)?.[0]);
            const role = actionMap.addContactRole?.find((role) => role.itemId === roleId);
            if (role) role.contact = contact;
        }
        delete actionMap.populateContactRole; // No longer needed

        // Parse resource quantity
        for (let resource of actionMap.addResource || []) {
            resource.number = Number(resource.itemValue.match(/-?\d+/)?.[0]) || 1;
        }

        // Parse checkbox state. Default to true if no value
        for (const attribute of actionMap.addCustAtrb || []) {
            attribute.checkbox = !attribute.itemValue?.endsWith("false");
        }

        return actionMap;
    }

    public static parseRuleItems(item: RuleItems): Rules.Conditions {
        const { operator, children } = item;
        return {
            operator,
            children:
                children.item?.map((child) => {
                    if (child.type === "bool") return S25RuleTreeUtil.parseRuleItems(child);
                    else return S25RuleTreeUtil.parseRuleItem(child);
                }) || [],
        };
    }

    public static parseRuleItem(item: RuleItem): Rules.Condition {
        const { operator, sourceItem, val_obj } = item;

        const valueParser = S25RuleTreeUtil.ruleValueParser(sourceItem);
        const condition: Rules.Condition = {
            type: sourceItem.itemTypeId,
            operator,
            values: val_obj?.map(valueParser) || [],
        };

        if ("itemId" in sourceItem) {
            condition.sourceItem = S25Util.extend(
                {
                    attributeType: sourceItem.custAtrbType,
                },
                sourceItem,
            );
        }
        if (sourceItem.itemTypeId === 11 && !sourceItem.custAtrbType) {
            condition.sourceItem = null;
        }

        return condition;
    }

    public static ruleValueParser(source: RuleSource): (val: RuleValue) => Rules.ConditionValue {
        return S25RuleTreeUtil.parseRuleValue.bind(this, source);
    }

    public static parseRuleValue(source: RuleSource, ruleValue: RuleValue): Rules.ConditionValue {
        const value = String(ruleValue.value);
        let itemName = ruleValue.itemName;

        if (source.itemTypeId === Rules.type.EventState.id) {
            itemName = EventStateConst.stateMap[Number(value)];
        }

        if (S25RuleTreeUtil.isItemType(source.itemTypeId) || S25RuleTreeUtil.isItemAttribute(source.custAtrbType)) {
            return { itemId: value, itemName };
        }

        if (Rules.typeIdToType[source.itemTypeId].valueType.type === "occurrenceDate") {
            return value.split(",").map((date) => S25Util.date.parse(date));
        }

        if (Rules.typeIdToType[source.itemTypeId].valueType.type === "occurrenceTime") {
            return value.split(",").map((hour) => S25Util.date.parse(S25Util.date.toTimeStrFromHours(Number(hour))));
        }

        if (Rules.typeIdToType[source.itemTypeId].valueType.type === "occurrenceDow") {
            return Object.fromEntries(value.split("").map((dow) => [dow, true])) as Record<DowChar, boolean>;
        }

        if (Rules.typeIdToType[source.itemTypeId].valueType.type === "search") {
            return { itemId: value } as ItemValue;
        }

        if (Rules.typeIdToType[source.itemTypeId].valueType.type === "relativeDate") {
            const [type, days] = value?.split(",") || ["eventStart", 0];
            return { days: Number(days), type } as ConditionValue;
        }

        switch (source.custAtrbType) {
            case Rules.attributeType.DateTime:
            case Rules.attributeType.Date:
            case Rules.attributeType.Time:
                return S25Util.date.parse(value);
            case Rules.attributeType.Boolean:
                return S25Util.toBool(value);
            case Rules.attributeType.Float:
                return S25Util.parseFloat(value);
            case Rules.attributeType.Integer:
                return S25Util.parseInt(value);
            case Rules.attributeType.Text:
            case Rules.attributeType.LongText:
            case Rules.attributeType.URL:
            default:
                return value;
        }
    }

    public static evaluateMathOperator<T extends number | string>(operator: Rules.Operator, value: T, threshold: T) {
        switch (operator) {
            case "<":
                return value < threshold;
            case ">":
                return value > threshold;
            case "=":
                return value === threshold;
            case "<=":
                return value <= threshold;
            case ">=":
                return value >= threshold;
            default:
                return false;
        }
    }
}
